/* eslint-disable import/no-anonymous-default-export */
'use client'

import { useEffect } from 'react'

import { NormalContainer } from '../components/layout/container/Normal'

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
export default ({ error, reset }: any) => {
  useEffect(() => {
    console.log('error', error)
    // captureException(error)
  }, [error])

  return (
    <NormalContainer>
      <div className='center flex min-h-[calc(100vh-10rem)] flex-col'>
        <h2 className='mb-5'>Something went wrong!</h2>
        <button onClick={reset}>Try again</button>
        {/* <StyledButton variant="primary" onClick={reset}>
          Try again
        </StyledButton> */}
      </div>
    </NormalContainer>
  )
}
