import { cn } from '@gym-rose/common'
import React, { FC } from 'react'

interface NormalContainerProps {
  children: React.ReactNode
  className?: string
}

export const NormalContainer: FC<NormalContainerProps> = ({
  children,
  className
}) => {
  return (
    <div
      className={cn(
        'mx-auto mt-14 max-w-3xl px-2 lg:mt-[80px] lg:px-0 2xl:max-w-4xl',
        'fill-content [&_header.prose]:mb-[80px]',
        className
      )}
    >
      {children}
    </div>
  )
}
